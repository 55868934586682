import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { formatBytes } from "../components/functions"
import "../components/cms/cms.scss"
import StaticHero from "../components/common/staticHero"
import BlockDeals from "../components/common/blockCarousel/blockDeals"
import { Container, Row } from "react-bootstrap"

export default function Award({ data: { nodeAward }, pageContext }) {
  const isSSR = typeof window === "undefined"

  const BlockWysiwyg = React.lazy(() =>
    import("../components/cms/blockWysiwyg")
  )

  const {
    title,
    field_seo,
    award_description: body,
    award_link,
    relationships: {
      award_media: {
        relationships: { field_image: bannerImage },
      },
      award_block_deals: blockDeals,
      award_documents,
      award_hotel: {
        drupal_id: hotelId,
        relationships: { node__offer: offers },
      },
    },
  } = nodeAward

  const intl = useIntl()
  const hotelOffers = offers
    ? offers.filter(offer => offer.title !== "Dummy")
    : []

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image:
      process.env.GATSBY_URL + bannerImage.gatsbyImage,
  })

  return (
    <Layout pageContext={pageContext} pageTitle={title}>
      <Seo data={seoData} pageContext={pageContext} />
      {bannerImage && (
        <StaticHero
          title={title}
          background={bannerImage.gatsbyImage}
        />
      )}
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          <BlockWysiwyg customClass="blk-spacing" text={body.value} />
          <section className="pb-50" data-scroll-section>
            <Container>
              <Row>
                <div className="cta-award d-flex" data-scroll>
                  {award_link && (
                    <a
                      class="blk-read-more linkExternal"
                      target="_blank"
                      href={award_link.uri}
                    >
                      <span>{award_link.title}</span>
                    </a>
                  )}
                  {award_documents && (
                    <a
                      class="blk-read-more linkDownload"
                      target="_blank"
                      href={
                        award_documents.relationships.field_media_file.gatsbyImage
                      }
                    >
                      <span>
                      {intl.formatMessage({ id: "listing.download" })}{" - "}{formatBytes(award_documents.relationships.field_media_file.filesize)}
                      </span>
                    </a>
                  )}
                </div>
              </Row>
            </Container>
          </section>
          {hotelOffers.length > 0 && blockDeals && (
            <BlockDeals
              hotelId={pageContext.hotelTaxonomyDrupalId}
              content={blockDeals}
              offers={hotelOffers}
            />
          )}
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodeAward(id: { eq: $id }) {
      id
      title
      field_seo {
        description
        title
        og_description
        og_image
        og_locale
        og_url
        og_title
        og_type
      }
      path {
        alias
        translated_urls
        langcode
      }
      award_link {
        uri
        title
      }
      award_description {
        processed
        value
      }
      disable_page
      relationships {
        award_hotel {
          drupal_id
          relationships {
            node__offer {
              title
              offer_featured_description {
                processed
              }
              path {
                alias
                langcode
              }
              relationships {
                offer_featured_media {
                  ...getFeaturedMedia
                }
                offer_hotel {
                  name
                }
              }
            }
          }
        }
        award_documents {
          relationships {
            field_media_file {
              filesize
              url
            }
          }
        }
        award_categories {
          name
          id
        }
        award_block_deals {
          block_deals_caption
          block_deals_description {
            processed
          }
          block_deals_link {
            title
          }
          block_deals_title
        }
        award_media {
          field_image {
            alt
          }
          relationships {
            ...getHeroImage
          }
        }
      }
    }
  }
`
