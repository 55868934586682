import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player"

const Media = ({ media, mediaClass }) => {
  let content
  const mediaClassName = mediaClass ? mediaClass : ""

  if (media) {
    if (media.relationships.field_image) {
      // Media is image
      content = (
        <GatsbyImage
          image={getImage(
            media.relationships.field_image.gatsbyImage
          )}
          className={mediaClassName}
          alt={media.field_image?.alt}
          quality="100"
        />
      )
    } else if (media.internal.type === "media__video") {
      // Media is video mp4
      content = (
        <div className="videoPlaceholder">
          <video
            className={mediaClassName + " videoAmbiance"}
            autoPlay={1}
            loop
            muted
            playsInline
            width="100%"
          >
            <source
              src={
                media.relationships.field_media_video_file.publicURL
              }
              type="video/mp4"
            />
          </video>
        </div>
      )
    } else if (media.internal.type === "media__wistia") {
      // Media is video wistia
      content = (
        <div className={"player-wrapper " + mediaClassName}>
          <ReactPlayer
            url={media.field_media_oembed_video}
            className="react-player"
            width="100%"
            config={{
              wistia: {
                options: {
                  videoFoam: true,
                  volumeControl: true,
                  playButton: true,
                },
              },
            }}
          />
        </div>
      )
    }
  }

  return <>{content}</>
}

export default Media
