import React from "react"
import { Col, Row } from "react-bootstrap"
import Title from "../title"
import parse from "html-react-parser"
import StyledButton from "../styledButton"

const Heading = props => {
  return (
    <div className="elem-heading">
      <div>
        {props.caption && (
          <span className="caption animTxt">{props.caption}</span>
        )}
        {props.title && <Title text={props.title} heading={props.heading} />}
        {props.description && (
          <div className="description">
             {parse(props.description)}
          </div>
        )}
      </div>
      {props.link && (
        <div className="buttonCol">
          <StyledButton
            title={props.link.title}
            type="link"
            path={props.link.url}
          />
        </div>
      )}
    </div>
  )
}

export default Heading
