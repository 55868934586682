import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick"
import "../../../assets/scss/plugins/_slick.scss"
import "./blockCarousel.scss"
import StyledButton from "../styledButton"
import parse from "html-react-parser"
import Heading from "../blockHeading/heading"
import { useIntl } from "gatsby-plugin-react-intl"
import Media from "../media"
import { graphql, Link } from "gatsby"
import { generateUrlByLocale } from "../../../services/intl.service"
import { trimSentence } from "../../functions"

const BlockDeals = ({ hotelId, content, offers }) => {

  const intl = useIntl()
  // const baseUrl = "https://reservations.veranda-resorts.com/?"

  // let hotelid, configCode, lang, themecode

  // switch (intl.locale) {
  //   case "fr":
  //     lang = "fr-FR"
  //     break
  //   default:
  //     lang = "en-US"
  // }
  // switch (hotelId) {
  //   case "0a860d53-0e43-4723-bebc-e4144174a4a2":
  //     hotelid = "59976"
  //     configCode = "new_BE"
  //     themecode = "TELFAIRTHEME"
  //     break
  //   case "84d7e9a8-d840-40da-b0c1-acc1fbf40545":
  //     hotelid = "59973"
  //     configCode = "HRA"
  //     themecode = "HRA"
  //     break
  //   case "e9a09b4c-d930-454f-9a91-9f755892f127":
  //     hotelid = "59995"
  //     configCode = "HRTV"
  //     themecode = "HRTV"
  //     break
  //   default:
  //     hotelid = ""
  // }

  // const finalUrl =
  //   baseUrl +
  //   "&hotel=" +
  //   hotelid +
  //   "&configCode=" +
  //   configCode +
  //   "&themecode=" +
  //   themecode +
  //   "&lang=" +
  //   lang +
  //   "&chain=14463&level=hotel&currency=EUR&rooms=1"

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <section
      className="blk-mea-slider deals"
    >
      <Container>
        <Row>
          <Col md={5}>
            <Heading
              caption={content.block_deals_caption}
              title={content.block_deals_title}
              description={content.block_deals_description.processed}
              link={content.block_deals_link}
            />
          </Col>
          <Col md={7}>
            <Slider {...settings}>
              {offers.map((item, index) => {
                return (
                  <div key={index}>
                    {item.relationships.offer_featured_media && <Media media={item.relationships.offer_featured_media.relationships.featured_media_media} />}
                    <div className="slider-text">
                      <Heading title={item.title} heading={3}  />
                      {item.offer_featured_description && (
                          <div className="description">
                            {parse(
                                trimSentence(
                                    item.offer_featured_description.processed,170
                                )
                            )}
                          </div>
                      )}
                      <div className="btnList">
                        <StyledButton path={generateUrlByLocale(item.path.langcode, item.path.alias)} title={intl.formatMessage({ id: "discover" })} type="link" />
                        {item.relationships.offer_introduction?.introduction_link && <StyledButton path={item.relationships.offer_introduction.introduction_link.url} title={item.relationships.offer_introduction.introduction_link.title} type="transparent" />}
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment deals on node__offer {
    title
    path {
      alias
      langcode
      translated_urls
    }
    offer_featured_description {
      processed
    }
    relationships {
      offer_featured_media {
        ...getFeaturedMedia
      }
      offer_introduction {
        introduction_link {
          title
          url
        }
      }
    }
  }
`
export default BlockDeals
